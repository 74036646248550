import Seo from 'src/components/seo/Seo'
import CartaoPresente from 'src/components/servicos/ServicesPage/CartaoPresente'

function Page() {
  return (
    <>
      <Seo title="Cartão Presente | Decathlon" />
      <CartaoPresente />
    </>
  )
}

export default Page
